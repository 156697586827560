a,
.link {
  background: transparent;
  border: none;
  color: #0000ee;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

a:visited,
.link:visited {
  color: #0000ee;
}
