/* autoprefixer grid: autoplace */

html,
body,
.root {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr minmax(50px, auto);
  grid-template-areas: "header" "inputs" "collections" "info-links" "footer";
  min-height: 100%;
}

.header {
  grid-area: header;
}

.inputs {
  grid-area: inputs;
}

.collections {
  background-color: #4ba7ab;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: collections;
  padding-top: 2rem;
}

.info-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-area: info-links;
}

.footer {
  grid-area: footer;
}

@media screen and (min-width: 769px) {
  .collections {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .collection {
    min-width: 500px;
  }

  .info-links {
    align-content: flex-start;
    flex-direction: row;
  }
}
