.footer {
  background-color: #ffc35c;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.footer p {
  margin-bottom: 1rem;
}

.thanks {
  margin-bottom: 0;
  margin-top: 2rem;
}

@media screen and (min-width: 769px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .thanks {
    margin: 0;
  }
}
