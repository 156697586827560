.card {
  background-color: #f5f5f5;
  /* border-radius: 4px; */
  font-size: 1rem;
  margin: 0 auto 36px;
  width: 85%;
}

.card_title {
  align-items: center;
  background-color: #4a4a4a;
  color: #fff;
  display: flex;
  padding: 0.75rem 1rem;
}

.card_title .left {
  font-size: 2rem;
  padding-right: 0.5rem;
}

.card_body {
  border-color: #4a4a4a;
  border-style: solid;
  border-width: 0 0 0 4px;
  color: #021310;
  line-height: 1.5rem;
  min-height: 8rem;
  padding: 1.25rem 1.5rem;
}

.card_body .strong {
  font-weight: 600;
}

.card--green {
  background-color: #f5fffd;
}

.card--green .card_title {
  background-color: #027c06;
  color: #fff;
}

.card--green .card_body {
  border-color: #027c06;
}

.card--gray .card_title {
  background-color: #a7abb2;
  color: #000;
}

.card--gray .card_body {
  border-color: #a7abb2;
}
