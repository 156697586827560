.header {
  background-color: #004c50;
  color: #ffffff;
  line-height: 1.125;
  padding: 1rem 2rem;
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 600;
}
