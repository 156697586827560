.postcode_input,
.postcode_submit {
  padding: 1rem;
  width: 100%;
}

.postcode_input {
  text-transform: uppercase;
}

.postcode_input:focus,
.postcode_submit:focus {
  border: 2px solid #ffc35c;
}


.postcode_submit {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  margin-bottom: 1rem;
}

@media screen and (min-width: 769px) {
  .postcode {
    min-width: 350px;
    width: 27rem;
  }

  .postcode_input,
  .postcode_submit {
    width: auto;
  }

  .postcode_submit {
    margin-bottom: 0;
  }
}
